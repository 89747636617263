import { useGLTF }       from '@react-three/drei'
import { useState }      from 'react'
import Z_Building_Page   from './components/building/Z_Building_Page'
import Z_Room_Page       from './components/room/Z_Room_Page'
import Z_Default_Section from './components/Z_Default_Section'
import './styles/globals.sass'
import closeIcon         from './svg/close-icon.svg'

const debug  = window.location.hash === '#debug'
window.debug = debug

export default function App() {
	
	const [ pageToShow, setPageToShow ] = useState( 'building-page' )
	const [ fullscreen, setFullScreen ] = useState( false )
	
	return ( <>
		{
			fullscreen
			? <div className={ 'fullscreen_section' }>
				<div className={ 'general_overlay' }>
					<img
							className={ 'close_icon' }
							src={ closeIcon }
							alt={ 'Close icon' }
							
							onClick={ () => {
								setFullScreen( false )
							} }
					/>
				</div>
				
				{ pageToShow === 'building-page' && <Z_Building_Page setPageToShow={ setPageToShow } /> }
				
				{ pageToShow === 'room-page' && <Z_Room_Page setPageToShow={ setPageToShow } /> }
			</div>
			: <Z_Default_Section
					setPageToShow={ setPageToShow }
					setFullScreen={ setFullScreen }
			/> }
	</> )
}


if ( process.env.NODE_ENV === 'production' ) {
	// Preload building model
	useGLTF.preload( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Building.glb` )
	
	// Preload building trees
	useGLTF.preload( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_01.gltf` )
	useGLTF.preload( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_02.gltf` )
	useGLTF.preload( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_03.gltf` )
	
	// Preload apartmnet model
	useGLTF.preload( `${ process.env.PUBLIC_URL }/ThreeJS_Files/nexity_model_baked_v2.glb` )
}
